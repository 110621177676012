<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage Examination Timetable for Year 2021</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Manage Examination Timetable for Year 2021
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <a @click="createExaminingTimetable()" class="btn btn-primary font-weight-bolder font-size-sm">
                    <span class="svg-icon svg-icon-md svg-icon-white"></span>
                    Add Examining Day in 2021
                  </a>&nbsp;
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col class="d-flex" cols="12" sm="3">
                  <v-select :items="weeks" label="Weeks*" outlined dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-btn class="ma-2"  style="color: #0BB7AF">
                    <v-icon small  elevation="2"
                            outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <table class="table">
                <thead>
                <tr class="text-center">
                  <th>Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Location</th>
                  <th>Week</th>
                  <th>Venue</th>
                  <th>Room</th>
                  <th>Supervisors</th>
                  <th>Examiners</th>
                  <th>Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr class="text-center">
                    <td>
                      <a href="#" class="">
                        Saurav Shrestha
                      </a>
                    </td>

                    <td>
                      <a href="#" class="">2077-04-18</a>
                    </td>

                    <td>
                      <a href="#" class=""> 9:00 AM-12:00 PM </a>
                    </td>

                    <td>
                      <a href="#" class="">Kamalpokhari,Kist College </a>
                    </td>
                    <td>
                      <a href="#" class="">Week 13 </a>
                    </td>
                    <td>
                      <a href="#" class="">Kamalpokhari,Kist College </a>
                    </td>
                    <td>
                      <a href="#" class="">Room 317 </a>
                    </td>
                    <td>
                      <a href="#" class="">Arjun Karki</a>
                    </td>
                    <td>
                      <a href="#" class="">Ramesh Budhathoki</a>
                    </td>
                    <td>
                        <span class="label label-lg label-inline"
                              v-bind:class="`label-light-success`"
                        >Active</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editExaminingTimetable()" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td>
                      <a href="#" class="">
                       Basanta Pandey
                      </a>
                    </td>

                    <td>
                      <a href="#" class="">2077-05-18</a>
                    </td>

                    <td>
                      <a href="#" class=""> 9:00 AM-12:00 PM </a>
                    </td>

                    <td>
                      <a href="#" class="">Kamalpokhari,Kist College </a>
                    </td>
                    <td>
                      <a href="#" class="">Week 16 </a>
                    </td>
                    <td>
                      <a href="#" class="">Kamalpokhari,Kist College </a>
                    </td>
                    <td>
                      <a href="#" class="">Room 311 </a>
                    </td>
                    <td>
                      <a href="#" class="">Hasila karki</a>
                    </td>
                    <td>
                      <a href="#" class="">TPK Kalfe</a>
                    </td>
                    <td>
                        <span class="label label-lg label-inline"
                              v-bind:class="`label-light-danger`"
                        >Inactive</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editExaminingTimetable()" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td>
                      <a href="#" class="">
                       Mohamad Faizan
                      </a>
                    </td>

                    <td>
                      <a href="#" class="">2077-05-19</a>
                    </td>

                    <td>
                      <a href="#" class=""> 9:00 AM-12:00 PM </a>
                    </td>

                    <td>
                      <a href="#" class="">Kamalpokhari,Kumari Hall </a>
                    </td>
                    <td>
                      <a href="#" class="">Week 14 </a>
                    </td>
                    <td>
                      <a href="#" class="">Kamalpokhari,Kumari Hall  </a>
                    </td>
                    <td>
                      <a href="#" class="">Room 318 </a>
                    </td>
                    <td>
                      <a href="#" class="">Yeshwant Pariyar</a>
                    </td>
                    <td>
                      <a href="#" class="">Kamal Dudhraj</a>
                    </td>
                    <td>
                        <span class="label label-lg label-inline"
                              v-bind:class="`label-light-danger`"
                        >Inactive</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editExaminingTimetable()" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        persistent
        height="500px"
        max-width="800px">
      <form enctype="multipart/form-data">
        <v-card>
          <v-card-title>
            <span >Examining Timetable Day</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      label="Day Name*"
                      outlined
                      dense
                      v-model="timetable.name">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      type="date"
                      label="State Prefix*"
                      outlined
                      dense
                      v-model="timetable.examination_date">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                      label="Category*"
                      outlined
                      :items="categories"
                      dense
                      v-model="timetable.category">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                      label="Syllabus*"
                      outlined
                      :items="syllabus"
                      dense
                      v-model="timetable.syllabus">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                      label="Location*"
                      outlined
                      :items="location"
                      dense
                      v-model="timetable.location">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                      label="Venue*"
                      outlined
                      :items="venue"
                      dense
                      v-model="timetable.venue">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                      label="Room*"
                      outlined
                      :items="rooms"
                      dense
                      v-model="timetable.room">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                      label="Weeks*"
                      outlined
                      :items="weeks"
                      dense
                      v-model="timetable.week">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                      type="time"
                      label="Start Time*"
                      outlined
                      dense
                      v-model="timetable.start_time">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                      type="time"
                      label="End Time*"
                      outlined
                      dense
                      v-model="timetable.end_time">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                      type="time"
                      label="TimeShift Hours*"
                      outlined
                      dense
                      v-model="timetable.timeshift_hours">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                      label="Exam Type*"
                      outlined
                      :items="exam_types"
                      dense
                      v-model="timetable.exam_type">
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                      v-model="timetable.examiner_verification"
                      label="Examiner Verification"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                      v-model="timetable.venue_verification"
                      label="Venue Verification"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                      v-model="timetable.status"
                      label="status"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                      v-model="timetable.need_supervisor"
                      label="Need SupervisorService"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="btn btn-primary"
                dark
                medium
                @click="dialog = false"
            >
              Save
            </v-btn>
            <v-btn
                color="btn btn-standard"
                dark
                medium
                @click="dialog = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </v-app>
</template>
<script>

export default {
  data() {
    return{
      dialog:false,
      weeks:['Week 15','Week 13','Week 13','Week 12','Week 11','Week 10','Week 09'],
      categories:['All','Keyboard','Strings','Brass','Voice','Percussion'],
      syllabus:['All','A','B','C','D','E'],
      location:['Kamlpokhari','Gyaneshowr','Pepsicola','Baneshowr','Bhaktapur','Sundhara'],
      venue:[],
      exam_types:['One Candidate at a time','All Candidate at the same time'],
      rooms:['Room 1','Room 2','Room 3','Room 4','Room 5','Room 6','Room 7'],
      timetable:{
        name:'',
        examination_date:'',
        category:'',
        syllabus:'',
        location:'',
        venue:'',
        room:'',
        week:'',
        start_time:'',
        end_time:'',
        timeshift_hours:'',
        exam_type:'',
        venue_verification:true,
        examiner_verification:true,
        status:true,
        need_supervisor:true,

      }
    }
  },
  methods: {
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    createExaminingTimetable(){
      this.resetForm();
      this.openDialog();
    },
    editExaminingTimetable(){
      this.openDialog();
      this.timetable={
        name:'',
        examination_date:'',
        category:'',
        syllabus:'',
        location:'',
        venue:'',
        room:'',
        week:'',
        start_time:'',
        end_time:'',
        timeshift_hours:'',
        exam_type:'',
        venue_verification:true,
        examiner_verification:true,
        status:true,
        need_supervisor:true,
      }
    },
    resetForm(){
      this.openDialog();
      this.timetable={
        name:'',
        prefix:'',
        notification_email:'',
        contact_email:'',
        phone:'',
        contact_person:'',
        contact_person_phone:'',
        contact_person_email:'',
        university_name:'',
        system_link:'',
        address:'',
        currency_symbol:'',
        is_country:true,
        alepay:true,
        paypal:true,
        paypal_type:true,
        school_pay:true,
        redirection:true,
        email_notification:true,
        api_authority:true,
      }

    },
    faqCategory(){
      this.$router.push({
        name: "faq-category",
      });
    },

  }
}
</script>
